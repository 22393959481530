import {
  IoIosArrowDown as ArrowDown,
  IoIosArrowUp as ArrowUp,
} from 'react-icons/io'
import { Checkbox, Rating, Table, TableBody } from '@mui/material'
import React, { useEffect, useState } from 'react'

import ApiManager from '../../../../api/ApiManager'
import Button from '../../../common/Button/Button.component'
import CardTable from '../../Common/CardTable.component'
import CarrierLogo from '../../../common/CarrierLogo'
import EnrollmentRequestConfirmation from '../../Modals/EnrollmentRequestConfirmation'
import { HttpMethods } from '../../../../enums/HttpMethodsEnum'
import { MNonSupplementPlan } from '../../../../interface/quotes/mNonSupplementPlans/MNonSupplementPlan'
import { MPartDPlan } from '../../../../interface/quotes/mNonSupplementPlans/medicarePartD/MPartDPlan'
import { MedicareQuotesType } from '../../../../enums/MedicareQuoteTypeEnum'
import ModalComponent from '../../../common/Modal/Modal.component'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableRow from '@mui/material/TableRow'
import PharmacyCoverage from '../../PharmacyCoverage.component'
import PrescriptionCoverage from '../../PrescriptionCoverage.component'
import ProviderCoverage from '../../ProviderCoverage.component'
import SavePlanIconButton from '../../../common/SavePlan/SavePlanIconButton'
import StringConstants from '../../../../constants/StringConstants'
import Tooltip from '../../../common/Tooltip/Tooltip.component'
import UrlConstants from '../../../../constants/UrlConstants'
import _ from 'lodash'
import { convertToUSCurrency } from '../../../../utils/CurrencyConverterUtil'
import customerStore from '../../../../datastore/CustomerStore'
import { fireEvent } from '../../../../cra'
import { getApiErrorMessage } from '../../../../utils/StringUtils'
import mPartDPlansFilterStore from '../../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import { observer } from 'mobx-react'
import snackbarStore from '../../../../datastore/SnackbarStore'
import { startCase } from 'lodash'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { updatePreferredNumberForEnrollRequestedPlan } from '../../../../service/customer'
import { useNavigate } from 'react-router-dom'
import userActionStore from '../../../../datastore/UserActionStore'

interface EstimatedAnnualCostProps {
  cost: number
  title: string
  tooltipTitle: string
}

interface PlanBenefit {
  name: string
  covered: boolean
  deductible?: number
}

const mccpdc =
  process.env.REACT_APP_CDN_BASE_URL +
  '/carriers/cost-plus-drug-company-logo.png'

const Container = styled.div`
  width: 900px;
  border: 1px solid ${theme.colors.borderColor};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 20px;
  overflow: hidden;

  @media screen and (max-width: 1250px) {
    width: 700px;
  }

  @media screen and (max-width: 1050px) {
    width: 600px;
  }

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 20px 0px;
  }
`

const HighlightChip = styled.div<{ type: string }>`
  padding: 10px 30px;
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.type === 'current'
      ? `${theme.colors.primary}`
      : `${theme.colors.green}`};
  p {
    font-size: 15px;
    font-weight: 700;
    color: ${theme.colors.textLight};
    span {
      font-weight: 400;
      font-style: italic;
    }
  }
  img {
    height: 15px;
    width: 15px;
    margin-right: 5px;
    margin-top: 2px;
  }
`

const Wrapper = styled.div`
  padding: 0px 30px;
  @media screen and (max-width: 530px) {
    padding: 0px 15px;
  }
`

const Header = styled.div`
  padding: 4px 20px;
  border-bottom: 1px solid ${theme.colors.borderColor};
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    img {
      width: 80px;
      margin-right: 20px;
    }
    h2 {
      font-size: 16px;
      color: ${theme.colors.textDark};
    }
    span {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 530px) {
    padding: 15px 10px;
    flex-direction: column;
    div {
      img {
        width: 60px;
        margin-right: 10px;
      }
      h2 {
        font-size: 16px;
      }
      span {
        font-size: 12px;
      }
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.borderColor};
  padding: 12px 0px;

  @media screen and (max-width: 530px) {
    padding: 20px 0px;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const BenefitsWrapper = styled.div`
  flex: 1.5;
  border-right: 1px solid ${theme.colors.borderColor};
  padding: 0px 20px;
  @media screen and (max-width: 530px) {
    padding: 0px 10px;
  }

  @media screen and (max-width: 500px) {
    border-right: none;
    border-bottom: 1px solid ${theme.colors.borderColor};
    margin-bottom: 20px;
  }
`

const PricingWrapper = styled.div`
  flex: 1;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  h2 {
    font-size: 14px;
    color: ${theme.colors.textGrey};
    padding-bottom: 5px;
  }
  p {
    font-size: 24px;
    font-weight: bold;
    color: ${theme.colors.primary};
    padding-bottom: 10px;
  }

  div {
    padding: 4px 0px 0px 0px;
    p {
      font-size: 18px;
      padding-bottom: 0px;
    }
    span {
      font-size: 12px;
      color: ${theme.colors.textGrey};
      font-weight: 600;
    }
  }

  @media screen and (max-width: 530px) {
    padding: 0px 10px;
  }
`

const BenefitContent = styled.div`
  padding-bottom: 8px;
  h2 {
    font-size: 14px;
    color: ${theme.colors.textGrey};
    padding-bottom: 10px;
  }
  div {
    display: flex;
    flex-wrap: wrap;
    .rxDeductible {
      font-size: 32px;
      font-weight: 700;
      color: ${theme.colors.primary};
    }
    p {
      padding-right: 15px;
      padding-bottom: 3px;
      font-size: 14px;
      color: ${theme.colors.textGrey};
      display: flex;
      align-items: center;
      span {
        margin-right: 2px;
      }
    }
  }
`

const FooterWrapper = styled.div`
  padding: 2px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
    }
    p {
      color: ${theme.colors.primary};
      font-size: 14px;
    }
  }

  @media screen and (max-width: 530px) {
    padding: 0px;
    padding-bottom: 10px;
    flex-wrap: wrap;
    div {
      flex-wrap: wrap;
      span {
        font-size: 12px;
      }
      p {
        font-size: 12px;
      }
    }
  }
`

const MoreDetailsWrapper = styled.div<{ isExpanded: boolean }>`
  padding: 20px;
  display: ${({ isExpanded }) => (isExpanded ? 'block' : 'none')};
  p {
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: 700;
    color: ${theme.colors.textGrey};
    span {
      padding-left: 10px;
      text-decoration: underline;
      font-weight: 400;
      color: ${theme.colors.primary};
      font-size: 12px;
    }
  }
`

const TableRow = styled(MuiTableRow)`
  &:nth-of-type(odd) {
    background-color: ${theme.colors.backgroundColorContainer};
  }
`

const TableCell = styled(MuiTableCell)`
  font-weight: 700;
  color: ${theme.colors.textGrey};
  font-size: 14px;
`
const TextWrapper = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: #2f3032;
  padding-right: 10px;
`

const AnnualCostContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0px !important;
  text-align: left;
  border-top: 1px solid ${theme.colors.borderColor};
  padding: 16px 0px 0px 0px !important;
  margin-top: 20px;
`

const MedicarePrescriptionCard: React.FC<{
  medicarePartDPlan: MNonSupplementPlan
  onEnroll: () => void
  handleRemoveFavorite?: () => void
  showCompareCheckbox?: boolean
}> = ({
  medicarePartDPlan,
  onEnroll,
  handleRemoveFavorite,
  showCompareCheckbox = true,
}) => {
    const [isExpanded, setIsExpanded] = React.useState(false)
    const [enrollRequested, setEnrollRequested] = React.useState(
      medicarePartDPlan.enrollRequested
    )
    const [enrollmentRequestPromptActive, setEnrollmentRequestPromptActive] =
      React.useState(false)
    const enrollBtnText = enrollRequested ? 'Enrollment Requested' : 'ENROLL'
    const [loading, setLoading] = React.useState(false)
    const handleExpandCard = (mpdPlanName: string) => {
      if (isExpanded === true) {
        customerStore.setSearchPlan('')
        customerStore.setScrollPosition('')
        setIsExpanded(false)
      }
      if (isExpanded === false) {
        setIsExpanded(true)
      }
      if (mpdPlanName === customerStore.searchPlan) {
        setIsExpanded(false)
        customerStore.setSearchPlan('')
        customerStore.setScrollPosition('')
      }
      setIsExpanded(!isExpanded)
    }
    const [preferredPhone, setPreferredPhone] = React.useState(
      customerStore.get().phone.replace('+1', '')
    )
    const [isFavorite, setIsFavorite] = React.useState(
      medicarePartDPlan.planSaved || false
    )
    const navigate = useNavigate()
    const [drugHighlights, setDrugHighlights] = useState<
      {
        key: string
        value: string
      }[]
    >([])

    let planBenefits: PlanBenefit[] = []

    let packageBenefits: any = {
      hasVision: medicarePartDPlan.hasVision,
      hasTransport: medicarePartDPlan.hasTransport,
      hasDental: medicarePartDPlan.hasDental,
      hasHearing: medicarePartDPlan.hasHearing,
      hasOTC: medicarePartDPlan.hasOTC,
      hasPreferredPharmacies: medicarePartDPlan.hasPreferredPharmacies,
      hasInsulinSavings: medicarePartDPlan.hasInsulinSavings,
      acceptsMailOrder: medicarePartDPlan.acceptsMailOrder,
      partBReimbursement: medicarePartDPlan.partBReimbursement,
    }

    Object.keys(packageBenefits).forEach((benefit) => {
      let covered = !(
        packageBenefits[benefit] === 'N' ||
        packageBenefits[benefit] === false ||
        packageBenefits[benefit] === -1
      )
      benefit = /^has/.test(benefit) ? benefit.slice(3) : benefit
      planBenefits.push({
        name: _.startCase(benefit).replace(/\s/g, ' '),
        covered: covered,
        deductible: undefined,
      })
    })

    const handleEnrollRequest = () => {
      const data = {
        $planId: medicarePartDPlan.id,
        quoteType: MedicareQuotesType.MEDICARE_NON_SUPPLEMENT,
        enrollRequested: true,
        versionId: process.env.REACT_APP_VERSION_ID,
        forSpouse: false,
        preferredPhoneNumber: '+1' + preferredPhone,
      }
      setLoading(true)
      ApiManager.makeApiCallWithAuthentication(
        UrlConstants.PATCH_ENROLL_REQUESTED.USECASE,
        HttpMethods.PATCH_METHOD,
        data
      )
        .then((response) => {
          if (response.status === 200) {
            setLoading(false)
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: 'Enroll request saved successfully',
              snackbarType: 'success',
            })
            setEnrollRequested(true)
            customerStore.setEnrollmentRequestedPlan({
              planId: medicarePartDPlan.id,
              planType: MedicareQuotesType.MEDICARE_PRESCRIPTION,
            })
            setEnrollmentRequestPromptActive(true)
          } else throw response
        })
        .catch((err) => {
          if (
            err.data?.message ===
            'Maximum limit reached for requesting enrollment for plans'
          ) {
            setLoading(false)
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: getApiErrorMessage(
                'save enroll request. Maximum limit reached'
              ),
              snackbarType: 'error',
            })
            setEnrollRequested(false)
            setEnrollmentRequestPromptActive(false)
          } else {
            setLoading(false)
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: getApiErrorMessage('save enroll request'),
              snackbarType: 'error',
            })
            setEnrollRequested(false)
            setEnrollmentRequestPromptActive(false)
          }
        })
    }

    const handlePreferredNumberForEnrollRequest = async () => {
      setLoading(true)
      if (preferredPhone !== customerStore.get().phone.replace('+1', '')) {
        updatePreferredNumberForEnrollRequestedPlan({ "plan-id": medicarePartDPlan.id, "plan-type": MedicareQuotesType.MEDICARE_NON_SUPPLEMENT, "preferred-number": preferredPhone })
          .then(() => {
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: "Successfully saved the preferred number",
              snackbarType: 'success',
            })
            setEnrollmentRequestPromptActive(false)
          })
          .catch((err) => {
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: "Please try again",
              snackbarType: 'error',
            })
          })
          .finally(() => {
            setLoading(false)
          })

      } else {
        setLoading(false)
        setEnrollmentRequestPromptActive(false)
      }

      onEnroll()

    }

    // const [medicarePartDPlan, setMPartDCard] = useState<MPartDPlan>()

    // useEffect(() => {
    //   setMPartDCard(medicarePartDPlan)
    // }, [medicarePartDPlan])

    const onAddFavPlan = (planId: string) => {
      // console.log('Adding plan to saved : ', planId)
      setIsFavorite(true)
    }

    const onRemoveFavPlan = (planId: string) => {
      // console.log('Removing plan from saved : ', planId)
      setIsFavorite(false)
      handleRemoveFavorite && handleRemoveFavorite()
    }

    // const [currentPlanAnnualCost, setCurrentPlanAnnualCost] =
    //   React.useState<number>(0)
    // const [currentPlanExist, setCurrentPlanExistStatus] =
    //   React.useState<boolean>(false)

    // if (medicarePartDPlan && medicarePartDPlan.customerCurrentPlanStatus) {
    //   setCurrentPlanAnnualCost(medicarePartDPlan.estimatedAnnualCost)
    //   setCurrentPlanExistStatus(true)
    // }

    const handleAddToCompare = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.checked) {
        if (
          userActionStore.comparePlanCards.planType ===
          MedicareQuotesType.MEDICARE_PRESCRIPTION
        ) {
          if (userActionStore.comparePlanCards.compareCardsData.length < 3) {
            userActionStore.addCard(MedicareQuotesType.MEDICARE_PRESCRIPTION, {
              id: medicarePartDPlan.id,
              name: medicarePartDPlan.name,
              premium: medicarePartDPlan.premium,
              carrier: medicarePartDPlan.carrierName,
              enrollRequestStatus: medicarePartDPlan.enrollRequested,
              planType: MedicareQuotesType.MEDICARE_NON_SUPPLEMENT,
              sunfirePlanId: medicarePartDPlan.sunfirePlanId,
              rating: medicarePartDPlan.fmtRating,
            })
          } else {
            snackbarStore.set({
              snackbarMessage: 'You can only add up to 3 plans for comparison',
              snackbarOpen: true,
              snackbarType: 'default',
            })
          }
        } else {
          userActionStore.addCard(MedicareQuotesType.MEDICARE_PRESCRIPTION, {
            id: medicarePartDPlan.id,
            name: medicarePartDPlan.name,
            premium: medicarePartDPlan.premium,
            carrier: medicarePartDPlan.carrierName,
            enrollRequestStatus: medicarePartDPlan.enrollRequested,
            planType: MedicareQuotesType.MEDICARE_NON_SUPPLEMENT,
            sunfirePlanId: medicarePartDPlan.sunfirePlanId,
            rating: medicarePartDPlan.fmtRating,
          })
        }
      }

      if (!e.currentTarget.checked) {
        userActionStore.removeCard(medicarePartDPlan.id)
      }
    }

    const storeScrollPosition = () => {
      const scrollPosition = window.scrollY
      customerStore.setScrollPosition(scrollPosition.toString())
    }

    const EstimatedAnnualCost: React.FC<EstimatedAnnualCostProps> = ({
      cost,
      title,
      tooltipTitle,
    }) => (
      <div>
        <AnnualCostContainer>
          <TextWrapper>{convertToUSCurrency(cost)}</TextWrapper>
          <div style={{ width: '100%' }}>
            <h2 style={{ paddingTop: '0px', fontSize: '14px' }}>{title}</h2>
          </div>
          <div style={{ paddingTop: '0px', marginLeft: '0px!important' }}>
            <Tooltip
              title={tooltipTitle}
              placement={
                title === 'Estimated Annual Cost' ? 'top-start' : 'bottom-start'
              }
            />
          </div>
        </AnnualCostContainer>
      </div>
    )

    return (
      <Container>
        {/* {medicarePartDPlan.isRecommended && (
        <HighlightChip type='Recommended'>
          <img src={recommendedIcon}></img>
          <p>Recommended</p>
        </HighlightChip> */}
        {/* )} */}
        {enrollmentRequestPromptActive && (
          <ModalComponent
            setOpen={() => {
              setEnrollmentRequestPromptActive(false)
            }}
            description='Popup - Request for Enrollment Confirmation'
            hideCloseButton={true}
          >
            <EnrollmentRequestConfirmation
              setOpen={() => {
                setEnrollmentRequestPromptActive(false)
                onEnroll()
              }}
              onEnroll={() => {
                handlePreferredNumberForEnrollRequest()
              }}
              planName={medicarePartDPlan.name}
              planId={medicarePartDPlan.id}
              planType={MedicareQuotesType.MEDICARE_PRESCRIPTION}
              buttonLoading={loading}
              preferredPhoneNumber={preferredPhone}
              setPreferredPhoneNumber={setPreferredPhone}
            />
          </ModalComponent>
        )}

        {medicarePartDPlan && (
          <Wrapper>
            <Header>
              <div>
                <CarrierLogo
                  carrier={medicarePartDPlan.carrierName}
                  useAltLogo={true}
                />
                <h2>{medicarePartDPlan.name}</h2>
              </div>
              {showCompareCheckbox && (
                <div>
                  <Checkbox
                    checked={
                      !!userActionStore.comparePlanCards.compareCardsData.find(
                        (iterator) => iterator.id === medicarePartDPlan.id
                      )
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      handleAddToCompare(e)
                      fireEvent(
                        !!userActionStore.comparePlanCards.compareCardsData.find(
                          (iterator) => iterator.id === medicarePartDPlan.id
                        )
                          ? 'addCardForComparison'
                          : 'removeAddedPlanForComparison',
                        e,
                        {
                          description:
                            !!userActionStore.comparePlanCards.compareCardsData.find(
                              (iterator) => iterator.id === medicarePartDPlan.id
                            )
                              ? 'Add Card for Comparison'
                              : 'Remove Card from Comparison',
                          planId: medicarePartDPlan.id,
                          planType: medicarePartDPlan.type,
                          planName: medicarePartDPlan.name,
                        }
                      )
                    }}
                  />
                  <span>Add to compare</span>
                </div>
              )}
            </Header>
            <ContentWrapper>
              <BenefitsWrapper>
                <BenefitContent>
                  <h2>Star Rating
                    {medicarePartDPlan.fmtRating === -1 && (
                      <Tooltip
                        title={StringConstants.TOOLTIP_NON_ELIGIBLE_STAR_RATING_MESSAGE}
                      />
                    )}
                  </h2>
                  <Rating
                    name='simple-controlled'
                    value={medicarePartDPlan.fmtRating || 0}
                    precision={0.5}
                    readOnly={true}
                    sx={{
                      marginBottom: '1.5rem',
                    }}
                  />
                  {medicarePartDPlan.contractYear === new Date().getFullYear() ||
                    (medicarePartDPlan.contractYear ===
                      new Date().getFullYear() + 1 &&
                      customerStore.getCalculateDrugCostForAEP()) ? (
                    <>
                      <PrescriptionCoverage
                        prescribedDrugsCoverage={
                          medicarePartDPlan.drugInformations
                        }
                      />
                    </>
                  ) : null}

                  <ProviderCoverage
                    providerCoverages={medicarePartDPlan.providerCoverage}
                  ></ProviderCoverage>

                  {medicarePartDPlan.drugCosts?.length > 0 && (
                    <PharmacyCoverage
                      pharmacyCoverages={medicarePartDPlan.drugCosts}
                    ></PharmacyCoverage>
                  )}
                </BenefitContent>
              </BenefitsWrapper>

              {/* {currentPlanExist && (
              <div>
                <p>
                  {convertToUSCurrency(
                    Math.abs(
                      currentPlanAnnualCost - medicarePartDPlan.estimatedAnnualCost
                    )
                  )}
                </p>
              </div>
            )} */}
              <PricingWrapper>
                <h2>Monthly Premium</h2>
                <p>{convertToUSCurrency(medicarePartDPlan.premium)}</p>
                <Button
                  variant='contained'
                  color='secondary'
                  width='100%'
                  onClick={() => {
                    handleEnrollRequest()
                  }}
                  loading={loading}
                  disabled={enrollRequested}
                  additionalPadding={true}
                  description={`Attempted Enrollment in ${medicarePartDPlan.name}`}
                  id={`enroll ${medicarePartDPlan.name}`}
                >
                  {enrollBtnText}
                </Button>
                <div>
                  {medicarePartDPlan.drugCosts?.length > 0 && (
                    <AnnualCostContainer>
                      <TextWrapper>{convertToUSCurrency(medicarePartDPlan.drugCost)}</TextWrapper>
                      <div style={{ width: '100%' }}>
                        <h2 style={{ paddingTop: '0px', fontSize: '14px' }}>Estimated Annual Cost</h2>
                      </div>
                      <div style={{ paddingTop: '0px', marginLeft: '0px!important' }}>
                        <Tooltip
                          title={StringConstants.TOOLTIP_ESTIMATED_ANNUAL_COST}
                          placement='top-start'
                        />
                      </div>
                    </AnnualCostContainer>
                  )}
                </div>
              </PricingWrapper>
            </ContentWrapper>
            <FooterWrapper>
              <SavePlanIconButton
                planId={medicarePartDPlan.id}
                planType={MedicareQuotesType.MEDICARE_NON_SUPPLEMENT}
                saved={isFavorite}
                onAddSaved={onAddFavPlan}
                onRemoveSaved={onRemoveFavPlan}
              />
              {planBenefits.length > 0 && (
                <div>
                  <Button
                    variant='text'
                    color='primary'
                    fontSize='14px'
                    borderRadius='4px'
                    bold={false}
                    endIcon={
                      isExpanded ||
                        medicarePartDPlan.name === customerStore.searchPlan ? (
                        <ArrowUp style={{ fontSize: '12px' }} />
                      ) : (
                        <ArrowDown style={{ fontSize: '12px' }} />
                      )
                    }
                    description={
                      isExpanded ||
                        medicarePartDPlan.name === customerStore.searchPlan
                        ? 'View Less Details'
                        : 'View More Details'
                    }
                    onClick={(e) => {
                      handleExpandCard(medicarePartDPlan.name)
                      fireEvent(
                        isExpanded ||
                          medicarePartDPlan.name === customerStore.searchPlan
                          ? 'viewLessCardDetails'
                          : 'viewMoreCardDetails',
                        e,
                        {
                          value:
                            !isExpanded ||
                              medicarePartDPlan.name !== customerStore.searchPlan
                              ? 'expanding'
                              : 'collapsing',
                          planId: medicarePartDPlan.id,
                          planType: MedicareQuotesType.MEDICARE_PRESCRIPTION,
                          description:
                            isExpanded ||
                              medicarePartDPlan.name === customerStore.searchPlan
                              ? 'View Less Card Details'
                              : 'View More Card Details',
                        }
                      )
                    }}
                  >
                    <>
                      View{' '}
                      {isExpanded ||
                        medicarePartDPlan.name === customerStore.searchPlan
                        ? 'less'
                        : 'more'}{' '}
                      details
                    </>
                  </Button>
                </div>
              )}
            </FooterWrapper>
            <MoreDetailsWrapper
              isExpanded={
                isExpanded || medicarePartDPlan.name === customerStore.searchPlan
              }
            >
              {drugHighlights.length > 0 && (
                <>
                  <p>
                    Drug Coverage Highlights{' '}
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        navigate({
                          pathname: '/plan-details',
                          search: `id=${medicarePartDPlan.id}&&type=mpd`,
                        })
                        storeScrollPosition()
                      }}
                    >
                      View complete plan details
                    </span>
                  </p>
                  <CardTable rows={drugHighlights} />
                </>
              )}
              {/* {coverage && (
              <>
                <p>
                  Coverage{' '}
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate({
                        pathname: '/plan-details',
                        search: `id=${medicarePartDPlan.id}&&type=mpd`,
                      })
                      storeScrollPosition()
                    }}
                  >
                    View complete plan details
                  </span>
                </p>
                <CardTable rows={coverage} />
              </>
            )} */}
              {planBenefits?.length > 0 && (
                <>
                  <p>
                    Plan Benefits{' '}
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        fireEvent('viewCompletePlanDetails', e, {
                          description:
                            'View Complete Plan Details of Medicare Advantage Card',
                          planId: medicarePartDPlan.id,
                          planType: medicarePartDPlan.type,
                          planName: medicarePartDPlan.name,
                        })
                        navigate({
                          pathname: '/plan-details',
                          search: `id=${medicarePartDPlan.id}&&type=mpd`,
                        })
                        storeScrollPosition()
                      }}
                    >
                      View complete plan details
                    </span>
                  </p>
                  <CardTable
                    rows={planBenefits.map((iterator) => ({
                      key: iterator.name,
                      value: iterator.covered ? 'Yes' : 'No',
                    }))}
                  />
                </>
              )}

              {medicarePartDPlan.drugCosts?.length > 0 && (
                <>
                  <p>
                    Drug costs for the year
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        navigate({
                          pathname: '/plan-details',
                          search: `id=${medicarePartDPlan.id}&&type=mpd`,
                        })
                        storeScrollPosition()
                      }}
                    >
                      View complete plan details
                    </span>
                  </p>

                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={1} style={{ textAlign: 'center' }}>
                          Pharmacy Name
                        </TableCell>
                        <TableCell colSpan={1} style={{ textAlign: 'center' }}>
                          Drug Price
                        </TableCell>
                      </TableRow>
                      {medicarePartDPlan.drugCosts.map((drugCost, index) => (
                        <TableRow key={drugCost.pharmacyId + '_' + index}>
                          <TableCell style={{ textAlign: 'center' }}>
                            {drugCost.pharmacyName}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {drugCost.fmtDrugCost}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
              <FooterWrapper>
                <SavePlanIconButton
                  planId={medicarePartDPlan.id}
                  planType={MedicareQuotesType.MEDICARE_NON_SUPPLEMENT}
                  saved={isFavorite}
                  onAddSaved={onAddFavPlan}
                  onRemoveSaved={onRemoveFavPlan}
                />
                <div>
                  <Button
                    variant='text'
                    color='primary'
                    fontSize='14px'
                    bold={false}
                    endIcon={
                      isExpanded ||
                        medicarePartDPlan.name === customerStore.searchPlan ? (
                        <ArrowUp style={{ fontSize: '12px' }} />
                      ) : (
                        <ArrowDown style={{ fontSize: '12px' }} />
                      )
                    }
                    description={
                      isExpanded ||
                        medicarePartDPlan.name === customerStore.searchPlan
                        ? 'View Less Details'
                        : 'View More Details'
                    }
                    onClick={(e) => {
                      handleExpandCard(medicarePartDPlan.name)
                      fireEvent(
                        isExpanded ||
                          medicarePartDPlan.name === customerStore.searchPlan
                          ? 'viewLessCardDetails'
                          : 'viewMoreCardDetails',
                        e,
                        {
                          value:
                            !isExpanded ||
                              medicarePartDPlan.name !== customerStore.searchPlan
                              ? 'expanding'
                              : 'collapsing',
                          planId: medicarePartDPlan.id,
                          planType: MedicareQuotesType.MEDICARE_PRESCRIPTION,
                          description: isExpanded
                            ? 'View Less Card Details'
                            : 'View More Card Details',
                        }
                      )
                    }}
                  >
                    <>
                      View{' '}
                      {isExpanded ||
                        medicarePartDPlan.name === customerStore.searchPlan
                        ? 'less'
                        : 'more'}{' '}
                      details
                    </>
                  </Button>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                      handleEnrollRequest()
                    }}
                    loading={loading}
                    disabled={enrollRequested}
                    additionalPadding={true}
                    description={`Attempted Enrollment in ${medicarePartDPlan.name}`}
                    id={`enroll ${medicarePartDPlan.name}`}
                  >
                    {enrollBtnText}
                  </Button>
                </div>
              </FooterWrapper>
            </MoreDetailsWrapper>
          </Wrapper>
        )}
      </Container>
    )
  }

export default observer(MedicarePrescriptionCard)
