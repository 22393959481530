import {
  IoIosArrowDown as ArrowDown,
  IoIosArrowUp as ArrowUp,
} from 'react-icons/io'
import { BsCheckLg, BsXLg } from 'react-icons/bs'
import { cloneDeep, isEmpty, isUndefined } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'

import ApiManager from '../../../../api/ApiManager'
import Button from '../../../common/Button/Button.component'
import CardTable from '../../Common/CardTable.component'
import CarrierLogo from '../../../common/CarrierLogo'
import { Checkbox } from '@mui/material'
import EnrollmentRequestConfirmation from '../../Modals/EnrollmentRequestConfirmation'
import { HttpMethods } from '../../../../enums/HttpMethodsEnum'
import { MSPlan } from '../../../../interface/quotes/mSupplementPlans/MSPlan'
import { MSPlanGenericDisplayDetail } from '../../../../interface/common/MSPlanGenericDisplayDetail'
import { MSPlansRiderType } from '../../../../interface/quotes/mSupplementPlans/MSPlansRiderType'
import { MedicareQuotesType } from '../../../../enums/MedicareQuoteTypeEnum'
import ModalComponent from '../../../common/Modal/Modal.component'
import NonStdToStdPlans from '../../../../constants/NonStdToStdPlans'
import React from 'react'
import RidersPlans from '../../../../constants/RidersPlans'
import RidersSelectionModal from '../../Modals/RidersSelectionModal'
import RouteConstants from '../../../../constants/RouteConstants'
import SavePlanIconButton from '../../../common/SavePlan/SavePlanIconButton'
import { StateAndMSPlanTypesConstants } from '../../../../constants/StateAndMSPlanTypes'
import StringConstants from '../../../../constants/StringConstants'
import Tooltip from '../../../common/Tooltip/Tooltip.component'
import UrlConstants from '../../../../constants/UrlConstants'
import { constructCompletePlanDetail } from '../../../../utils/MedicareQuotesUtils'
import { convertToUSCurrency } from '../../../../utils/CurrencyConverterUtil'
import customerStore from '../../../../datastore/CustomerStore'
import { fireEvent } from '../../../../cra'
import { getApiErrorMessage } from '../../../../utils/StringUtils'
import msPlanFiltersStore from '../../../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import { observer } from 'mobx-react'
import recommendedIcon from '../../../../assets/vectors/recommendedIcon.svg'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { updatePreferredNumberForEnrollRequestedPlan } from '../../../../service/customer'
import userActionStore from '../../../../datastore/UserActionStore'

// import { MSPlanGenericDetails } from '../../../../interface/quotes/mSupplementPlans/MSPlanGenericDetails'

const Container = styled.div`
  width: 900px;
  border: 1px solid ${theme.colors.borderColor};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 20px;
  overflow: hidden;

  @media screen and (max-width: 1250px) {
    width: 700px;
  }

  @media screen and (max-width: 1050px) {
    width: 600px;
  }

  @media screen and (max-width: 640px) {
    width: 100%;
    margin: 20px 0px;
  }
`

const HighlightChip = styled.div<{ type: string }>`
  padding: 10px 30px;
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.type === 'current'
      ? `${theme.colors.primary}`
      : `${theme.colors.green}`};
  p {
    font-size: 15px;
    font-weight: 700;
    color: ${theme.colors.textLight};
    span {
      font-weight: 400;
      font-style: italic;
    }
  }
  img {
    height: 15px;
    width: 15px;
    margin-right: 5px;
    margin-top: 2px;
  }
`

const Wrapper = styled.div`
  padding: 0px 30px;
  @media screen and (max-width: 530px) {
    padding: 0px 15px;
  }
`

const Header = styled.div`
  padding: 4px 20px;
  border-bottom: 1px solid ${theme.colors.borderColor};
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    img {
      width: 80px;
      margin-right: 20px;
    }
    h2 {
      font-size: 16px;
      color: ${theme.colors.textDark};
    }
    span {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 530px) {
    padding: 15px 10px;
    flex-direction: column;
    div {
      img {
        width: 60px;
        margin-right: 10px;
      }
      h2 {
        font-size: 16px;
      }
      span {
        font-size: 12px;
      }
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.borderColor};
  padding: 16px 0px;

  @media screen and (max-width: 530px) {
    padding: 20px 0px;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const BenefitsWrapper = styled.div`
  flex: 1.5;
  border-right: 1px solid ${theme.colors.borderColor};
  padding: 0px 30px;
  @media screen and (max-width: 530px) {
    padding: 0px 10px;
  }

  @media screen and (max-width: 500px) {
    border-right: none;
    border-bottom: 1px solid ${theme.colors.borderColor};
    margin-bottom: 20px;
  }
`

const PricingWrapper = styled.div`
  flex: 1;
  padding: 6px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  h2 {
    font-size: 14px;
    color: ${theme.colors.textGrey};
    // padding-bottom: px;
  }
  p {
    font-size: 24px;
    font-weight: bold;
    color: ${theme.colors.primary};
    padding-bottom: 10px;
    :nth-of-type(1) {
      padding: 4px 0px;
    }
  }

  .info {
    font-size: 12px;
    font-weight: 600;
    color: ${theme.colors.textDark};
  }

  div {
    padding: 4px 0px 0px 0px;
    p {
      margin-top: 4px;
      font-size: 14px;
    }
    span {
      font-size: 12px;
      color: ${theme.colors.textGrey};
      font-weight: 600;
    }
  }
  .discounts {
    span {
      margin-right: 6px;
    }
  }

  @media screen and (max-width: 1250px) {
    .discounts {
      :nth-of-type(1) {
        max-width: 100px;
      }
      :nth-of-type(2) {
        max-width: 60px;
        padding-right: 4px;
        padding-top: 16px;
      }
      :nth-of-type(3) {
        padding-top: 16px;
      }
    }
  }
  @media screen and (max-width: 623px) {
    .policyFee {
      :nth-of-type(1) {
        max-width: 60px;
      }
      :nth-of-type(2) {
        max-width: 60px;
        padding-right: 4px;
        padding-top: 16px;
      }
      :nth-of-type(3) {
        padding-top: 16px;
      }
    }
  }
  @media screen and (max-width: 565px) {
    padding: 6px 10px;
  }
  @media screen and (max-width: 545px) {
    padding: 0px 10px;
  }
  @media screen and (max-width: 533px) {
    .discounts {
      :nth-of-type(2),
      :nth-of-type(3) {
        padding-top: 30px;
      }
    }
  }
  @media screen and (max-width: 530px) {
    padding: 6px 10px;
    .discounts {
      :nth-of-type(2),
      :nth-of-type(3) {
        padding-top: 16px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    padding: 0px 10px;

    p {
      padding-bottom: 10px;
    }
    .discounts,
    .policyFee {
      :nth-of-type(1) {
        max-width: initial;
      }
      :nth-of-type(2) {
        max-width: initial;
        padding-right: initial;
        padding-top: initial;
      }
      :nth-of-type(3) {
        padding-top: initial;
      }
    }
  }
`

const BenefitContent = styled.div`
  padding-bottom: 8px;
  h2 {
    font-size: 14px;
    color: ${theme.colors.textGrey};
    padding-bottom: 10px;
  }
  p {
    font-size: 12px;
    color: ${theme.colors.textGrey};
    padding-bottom: 10px;
    display: flex;
  }
  .deductible {
    font-size: 36px;
    font-weight: bold;
    color: ${theme.colors.primary};
  }
  div {
    display: flex;
    flex-wrap: wrap;
    p {
      padding-right: 15px;
      padding-bottom: 3px;
      font-size: 14px;
      color: ${theme.colors.textGrey};
      display: flex;
      align-items: center;
      span {
        margin-right: 2px;
      }
    }
  }
`

const BenefitsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const FeaturesRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 1.5rem;
  font-size: 14px;
`

const FeaturesList = styled.ul`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`

const FeaturesListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 2px 0;
  margin-left: 48px;
`

const FeatureName = styled.span`
  margin-left: 8px;
  fontsize: 12px;
`

const FooterWrapper = styled.div`
  padding: 2px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
    }
    p {
      color: ${theme.colors.primary};
      font-size: 14px;
    }
  }

  @media screen and (max-width: 530px) {
    padding: 0px;
    padding-bottom: 10px;
    flex-wrap: wrap;
    div {
      flex-wrap: wrap;
      span {
        font-size: 12px;
      }
      p {
        font-size: 12px;
      }
    }
  }
`

const MoreDetailsWrapper = styled.div<{ isExpanded: boolean }>`
  padding: 20px;
  display: ${({ isExpanded }) => (isExpanded ? 'block' : 'none')};
  p {
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: 700;
    color: ${theme.colors.textGrey};
    span {
      padding-left: 10px;
      text-decoration: underline;
      font-weight: 400;
      color: ${theme.colors.primary};
      font-size: 12px;
    }
  }
`

// interface MSPlanWithGenericDetails extends MSPlan {
//   genericPlanDetails: MSPlanGenericDetails[]
// }

const MedicareSupplementCard: React.FC<{
  msPlan: MSPlan
  onEnroll: () => void
  handleRemoveFavorite?: () => void
  showCompareCheckbox?: boolean
  onReload?: () => void
  medigapChartDetails?: any
}> = ({
  msPlan,
  onEnroll,
  handleRemoveFavorite,
  showCompareCheckbox = true,
  onReload,
  medigapChartDetails
}) => {
    const [isExpanded, setIsExpanded] = React.useState(false)
    const [spouse, setSpouse] = React.useState(false)
    const [enrollRequested, setEnrollRequested] = React.useState(
      msPlan.enrollRequested
    )
    // const [modifiedPlanDetail, setModifiedPlanDetail] =
    //   React.useState<MSPlanGenericDisplayDetail>()
    const [enrollmentRequestPromptActive, setEnrollmentRequestPromptActive] =
      React.useState(false)
    const enrollBtnText = enrollRequested ? 'Application Requested' : 'APPLY'
    const [loading, setLoading] = React.useState(false)
    // const [msPlan, setMSPlan] = React.useState<MSPlan>()
    const [riders, setRiders] = React.useState<MSPlansRiderType[]>([])
    const handleExpandCard = (msPlan: MSPlan) => {
      customerStore.setMSExpandedCardDetails(
        cloneDeep(constructCompletePlanDetail(msPlan))
      )
      if (isExpanded === true) {
        customerStore.setSearchPlan('')
        customerStore.setScrollPosition('')
        setIsExpanded(false)
      }
      if (isExpanded === false) {
        setIsExpanded(true)
      }
      if (
        `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})` ===
        customerStore.searchPlan
      ) {
        setIsExpanded(false)
        customerStore.setSearchPlan('')
        customerStore.setScrollPosition('')
      }
    }
    const navigate = useNavigate()
    const [isFavorite, setIsFavorite] = React.useState(msPlan.planSaved || false)
    const [openRiders, setOpenRiders] = React.useState(false)
    const [monthlyPremium, setMonthlyPremium] = React.useState(
      msPlan.mSupplementRate.month / 100
    )
    const [annualCost, setAnnualCost] = React.useState(msPlan.estimatedAnnualCost)
    const [showRiders, setShowRiders] = React.useState(false)
    const [stdPlans, setStdPlans] = React.useState<string[]>([])
    const [applyRidersLoading, setApplyRidersLoading] = React.useState(false)
    const [preferredPhone, setPreferredPhone] = React.useState(
      customerStore.get().phone.replace('+1', '')
    )
    const location = useLocation()

    React.useEffect(() => {
      const tempRiders: MSPlansRiderType[] = []
      let tempStdPlans: string[] = []
      msPlan.riders.forEach((rider: any) => {
        let x: MSPlansRiderType = JSON.parse(rider)
        tempRiders.push(x)
      })
      setRiders(tempRiders)
      let tempShowRiders: boolean | undefined
      if (!isEmpty(tempRiders)) {
        if (Object.keys(RidersPlans).includes(msPlan.msCompanyBase.name)) {
          if (!isEmpty(RidersPlans[msPlan.msCompanyBase.name])) {
            if (
              Object.keys(RidersPlans[msPlan.msCompanyBase.name]).includes(
                msPlan.planName
              )
            ) {
              if (
                !isEmpty(RidersPlans[msPlan.msCompanyBase.name][msPlan.planName])
              ) {
                tempStdPlans =
                  NonStdToStdPlans[msPlan.msCompanyBase.name][msPlan.planName]
                tempShowRiders = true
              } else tempShowRiders = false
            }
          }
        }
        if (isUndefined(tempShowRiders)) {
          //carrierKey === 'Others'
          if (Object.keys(RidersPlans['Others']).includes(msPlan.planName)) {
            if (isEmpty(RidersPlans['Others'][msPlan.planName])) {
              tempShowRiders = false
            } else {
              tempStdPlans = NonStdToStdPlans['Others'][msPlan.planName]
              tempShowRiders = true
            }
          } else tempShowRiders = true
        }
        tempShowRiders = tempShowRiders || false
        setStdPlans(tempStdPlans)
        setShowRiders(tempShowRiders)
      }
      let ridersApplied = msPlan.ridersApplied
      if (!isUndefined(ridersApplied)) {
        if (customerStore.get().isRidersApplied) {
          setMonthlyPremium(msPlan.mSupplementRate.monthWithRidersApplied / 100)
          setAnnualCost(
            msPlan.estimatedAnnualCost +
            ((msPlan.mSupplementRate.monthWithRidersApplied -
              msPlan.mSupplementRate.month) *
              12) /
            100
          )
        }
      }
    }, [])

    const handleOpenRiders = () => {
      setOpenRiders(!openRiders)
    }

    const handleEnrollRequest = () => {
      if (
        isEmpty(userActionStore.getRidersApplied(msPlan.id)) &&
        !isEmpty(msPlan.ridersApplied)
      ) {
        userActionStore.setRidersApplied(msPlan.id, msPlan.ridersApplied!)
      }
      setLoading(true)
      const riders = msPlan.id
        ? userActionStore.getRidersAppliedWithFullNames(msPlan.id).length > 0
          ? userActionStore.getRidersAppliedWithFullNames(msPlan.id)
          : undefined
        : undefined
      ApiManager.makeApiCallWithAuthentication(
        UrlConstants.PATCH_ENROLL_REQUESTED.USECASE,
        HttpMethods.PATCH_METHOD,
        {
          $planId: msPlan.id,
          'quoteType': MedicareQuotesType.MEDICARE_SUPPLEMENT,
          'enrollRequested': true,
          'forSpouse': customerStore.get().forSpouse,
          versionId: process.env.REACT_APP_VERSION_ID,
          rider: riders?.toString(),
          'preferredPhoneNumber': '+1' + preferredPhone,
        }
      )
        .then((response) => {
          if (response.status === 200) {
            setLoading(false)
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: 'Apply request saved successfully',
              snackbarType: 'success',
            })
            setEnrollRequested(true)
            customerStore.setEnrollmentRequestedPlan({
              planId: msPlan.id,
              planType: MedicareQuotesType.MEDICARE_SUPPLEMENT,
            })
            setEnrollmentRequestPromptActive(true)
          } else throw response
        })
        .catch((err) => {
          if (
            err.data?.message ===
            'Maximum limit reached for requesting enrollment for plans'
          ) {
            setLoading(false)
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: getApiErrorMessage(
                'save apply request. Maximum limit reached'
              ),
              snackbarType: 'error',
            })
            setEnrollRequested(false)
            setEnrollmentRequestPromptActive(false)
          } else {
            setLoading(false)
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: getApiErrorMessage('save apply request'),
              snackbarType: 'error',
            })
            setEnrollRequested(false)
            setEnrollmentRequestPromptActive(false)
          }
        })
    }

    const handlePreferredNumberForEnrollRequest = async () => {
      setLoading(true)
      if (preferredPhone !== customerStore.get().phone.replace('+1', '')) {
        updatePreferredNumberForEnrollRequestedPlan({ "plan-id": msPlan.id, "plan-type": MedicareQuotesType.MEDICARE_SUPPLEMENT, "preferred-number": preferredPhone })
          .then(() => {
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: "Successfully saved the preferred number",
              snackbarType: 'success',
            })
            setEnrollmentRequestPromptActive(false)
          })
          .catch((err) => {
            snackbarStore.set({
              snackbarOpen: true,
              snackbarMessage: "Please try again",
              snackbarType: 'error',
            })
          })
          .finally(() => {
            setLoading(false)
          })

      } else {
        setLoading(false)
        setEnrollmentRequestPromptActive(false)
      }

      onEnroll()

    }

    const updateRidersApplied = (planId: string) => {
      setApplyRidersLoading(true)
      ApiManager.makeApiCallWithAuthentication(
        UrlConstants.PATCH_RIDERS_APPLIED_FOR_MS_PLAN.USECASE,
        HttpMethods.PATCH_METHOD,
        {
          $planId: planId,
          ridersApplied: userActionStore.getRidersApplied(planId),
        }
      )
        .then((response) => {
          if (response.status === 200) {
            customerStore.setIsRidersApplied(true)
            msPlanFiltersStore.setFilterApplied(true)
            if (location.pathname === RouteConstants.SAVED_PLANS)
              onReload && onReload()
          } else throw response
        })
        .catch((err) => {
          snackbarStore.set({
            snackbarMessage: getApiErrorMessage('update riders for the plan'),
            snackbarOpen: true,
            snackbarType: 'error',
          })
        })
        .finally(() => {
          setOpenRiders(false)
          setApplyRidersLoading(false)
        })
    }

    const handleRidersApplied = (plan: MSPlan) => {
      updateRidersApplied(plan.id)
    }

    // const [currentPlanAnnualCost, setCurrentPlanAnnualCost] =
    //   React.useState<number>(0)
    // const [combinedCurrentPlanAnnualCost, setCombinedCurrentPlanAnnualCost] =
    //   React.useState<number>(0)
    // const [currentPlanExist, setCurrentPlanExistStatus] =
    //   React.useState<boolean>(false)

    // React.useEffect(() => {
    //   setMSPlan(msPlan)
    // }, [msPlan])

    // if (msPlan && msPlan.currentPlanStatus) {
    //   setCurrentPlanAnnualCost(msPlan.estimatedAnnualCost)
    //   setCombinedCurrentPlanAnnualCost(msPlan.combinedEstimatedAnnualCost)
    //   setCurrentPlanExistStatus(true)
    // }

    const onAddFavPlan = (planId: string) => {
      setIsFavorite(true)
    }

    const onRemoveFavPlan = (planId: string) => {
      setIsFavorite(false)
      handleRemoveFavorite && handleRemoveFavorite()
    }

    const handleAddToCompare = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.checked) {
        if (
          userActionStore.comparePlanCards.planType ===
          MedicareQuotesType.MEDICARE_SUPPLEMENT
        ) {
          if (userActionStore.comparePlanCards.compareCardsData.length < 3) {
            userActionStore.addCard(MedicareQuotesType.MEDICARE_SUPPLEMENT, {
              id: msPlan.id,
              name: `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})`,
              premium: msPlan.mSupplementRate.month / 100,
              carrier: msPlan.msCompanyBase.name,
              enrollRequestStatus: msPlan.enrollRequested,
              planType: MedicareQuotesType.MEDICARE_SUPPLEMENT,
              medicareSupplementPlanType: 'Plan ' + msPlan.planName,
              ridersApplied: msPlan.ridersApplied,
              enrollmentRedirectURL: msPlan.enrollmentRedirectURL
            })
          } else {
            snackbarStore.set({
              snackbarMessage: 'You can only add up to 3 plans for comparison',
              snackbarOpen: true,
              snackbarType: 'default',
            })
          }
        } else {
          userActionStore.addCard(MedicareQuotesType.MEDICARE_SUPPLEMENT, {
            id: msPlan.id,
            name: `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})`,
            premium: msPlan.mSupplementRate.month / 100,
            carrier: msPlan.msCompanyBase.name,
            enrollRequestStatus: msPlan.enrollRequested,
            planType: MedicareQuotesType.MEDICARE_SUPPLEMENT,
            medicareSupplementPlanType: 'Plan ' + msPlan.planName,
            ridersApplied: msPlan.ridersApplied,
            enrollmentRedirectURL: msPlan.enrollmentRedirectURL
          })
        }
      }

      if (!e.currentTarget.checked) {
        userActionStore.removeCard(msPlan.id)
      }
    }

    const getDetailedDescription = (planName: string) => {
      let detailedDescription = planName
      if (planName.startsWith('MA')) {
        detailedDescription += ' (Massachussets'
        switch (planName) {
          case 'MA_CORE':
            detailedDescription += ' Core Plan)'
            break
          case 'MA_SUPP1':
            detailedDescription += ' Supplement 1 Plan)'
            break
          case 'MA_SUPP1A':
            detailedDescription += ' Supplement 1A Plan)'
            break
          default:
            detailedDescription = planName
        }
      } else if (planName.startsWith('MN')) {
        detailedDescription += ' (Minnesota'
        switch (planName) {
          case 'MN_PBCO':
            detailedDescription += ' Part B Copay/Coinsurance Plan)'
            break
          case 'MN_BASIC':
            detailedDescription += ' Basic Plan)'
            break
          case 'MN_EXTB':
            detailedDescription += ' Extended Basic Plan)'
            break
          case 'MN_XBAS2':
            detailedDescription += ' Extended Basic Plan - New)'
            break
          case 'MN_HDED':
            detailedDescription += ' High Deductible Plan)'
            break
          case 'MN_HDED2':
            detailedDescription += ' High Deductible Plan - New)'
            break
          case 'MN_50%':
            detailedDescription += ' 50% Cost Sharing Plan)'
            break
          case 'MN_75%':
            detailedDescription += ' 75% Cost Sharing Plan)'
            break
          case 'MN_A50%':
            detailedDescription += ' 50 percent Part A Deductible Coverage Plan)'
            break
          default:
            detailedDescription = planName
        }
      } else if (planName.startsWith('WI')) {
        detailedDescription += ' (Wisconsin'
        switch (planName) {
          case 'WI_BASE':
            detailedDescription += ' Basic Plan)'
            break
          case 'WI_HDED':
            detailedDescription += ' High Deductible Plan)'
            break
          case 'WI_50%':
            detailedDescription += ' 50% Cost Sharing Plan)'
            break
          case 'WI_75%':
            detailedDescription += ' 75% Cost Sharing Plan)'
            break
          default:
            detailedDescription = planName
        }
      } else {
        if (planName === 'HDF') detailedDescription += ' (High Deductible Plan F)'
        else if (planName === 'HDG')
          detailedDescription += ' (High Deductible Plan G)'
      }
      return detailedDescription
    }

    const storeScrollPosition = () => {
      const scrollPosition = window.scrollY
      customerStore.setScrollPosition(scrollPosition.toString())
    }

    return (
      <Container>
        {/* {msPlan.isRecommended && (
        <HighlightChip type='Recommended'>
          <img src={recommendedIcon}></img>
          <p>Recommended</p>
        </HighlightChip>
      )} */}
        {enrollmentRequestPromptActive && (
          <ModalComponent
            setOpen={() => {
              setEnrollmentRequestPromptActive(false)
            }}
            description='Popup - Request for Enrollment Confirmation'
            hideCloseButton={true}
          >
            <EnrollmentRequestConfirmation
              setOpen={() => {
                setEnrollmentRequestPromptActive(false)
              }}
              onEnroll={() => {
                handlePreferredNumberForEnrollRequest()
              }}
              planName={
                msPlan.msCompanyBase.name + ' (Plan ' + msPlan.planName + ')'
              }
              planId={msPlan.id}
              planType={MedicareQuotesType.MEDICARE_SUPPLEMENT}
              buttonLoading={loading}
              preferredPhoneNumber={preferredPhone}
              setPreferredPhoneNumber={setPreferredPhone}
              enrollmentRedirectURL={msPlan.enrollmentRedirectURL}
            />
          </ModalComponent>
        )}

        {openRiders && (
          <ModalComponent
            setOpen={() => {
              setOpenRiders(false)
            }}
            description='Popup - Select Riders'
          >
            <RidersSelectionModal
              setOpen={() => {
                setOpenRiders(false)
              }}
              buttonLoading={applyRidersLoading}
              planId={msPlan.id}
              planName={msPlan.planName}
              carrierName={msPlan.msCompanyBase.name}
              planRiders={riders}
              onApply={() => {
                handleRidersApplied(msPlan)
              }}
              stdPlans={stdPlans}
              appliedRiders={
                msPlan.ridersApplied
                  ? msPlan.ridersApplied
                  : userActionStore.getRidersApplied(msPlan.id)
              }
              isPlanEnrolmentRequested={msPlan.enrollRequested}
            />
          </ModalComponent>
        )}

        {msPlan && (
          <Wrapper>
            <Header>
              <div>
                <CarrierLogo
                  carrier={msPlan.msCompanyBase.name}
                  useAltLogo={true}
                />
                <h2>{`${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})`}</h2>
              </div>
              {showCompareCheckbox && (
                <div>
                  <Checkbox
                    checked={
                      !!userActionStore.comparePlanCards.compareCardsData.find(
                        (iterator) => iterator.id === msPlan.id
                      )
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      handleAddToCompare(e)
                      fireEvent(
                        !!userActionStore.comparePlanCards.compareCardsData.find(
                          (iterator) => iterator.id === msPlan.id
                        )
                          ? 'addCardForComparison'
                          : 'removeAddedPlanForComparison',
                        e,
                        {
                          description:
                            !!userActionStore.comparePlanCards.compareCardsData.find(
                              (iterator) => iterator.id === msPlan.id
                            )
                              ? 'Add Card for Comparison'
                              : 'Remove Card from Comparison',
                          planId: msPlan.id,
                          planType: msPlan.planName,
                          planName: msPlan.msCompanyBase + ' ' + msPlan.planName,
                        }
                      )
                    }}
                  />
                  <span>Add to compare</span>
                </div>
              )}
            </Header>
            <ContentWrapper>
              <BenefitsWrapper>
                <BenefitContent>
                  <BenefitsRow>
                    {msPlan.planName && (
                      <>
                        <h2
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          Plan
                          <span
                            style={{
                              color: theme.colors.primary,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginLeft: '0.25rem',
                            }}
                          >
                            {getDetailedDescription(msPlan.planName)}
                          </span>
                        </h2>
                      </>
                    )}
                    {msPlan.msCompanyBase &&
                      msPlan.msCompanyBase.ambestRating && (
                        <>
                          <h2
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            AM Best Rating -
                            <span
                              style={{
                                color: theme.colors.primary,
                                border: `solid 1px ${theme.colors.primary}`,
                                width: '2rem',
                                height: '2rem',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginLeft: '0.5rem',
                              }}
                            >
                              {msPlan.msCompanyBase.ambestRating}
                            </span>
                          </h2>
                        </>
                      )}
                  </BenefitsRow>
                  {/* TODO when BE sends in the Benefits Details */}
                  {StateAndMSPlanTypesConstants.StdPlans.includes(
                    msPlan.planName
                  ) &&
                    msPlan.planFeatures &&
                    msPlan.planFeatures.length > 0 && medigapChartDetails && (
                      <FeaturesRow>
                        <span>Plan Features: </span>
                        <FeaturesList>
                          {medigapChartDetails[msPlan.planName] && medigapChartDetails[msPlan.planName].part_a_deductible_1600 !== '0%' ? (
                            <FeaturesListItem>
                              {medigapChartDetails[msPlan.planName].part_a_deductible_1600 === '100%' ? (
                                <BsCheckLg
                                  fontSize='14px'
                                  color={theme.colors.green}
                                />
                              ) : (
                                <span style={{ color: 'rgb(69 147 8)' }}>{medigapChartDetails[msPlan.planName].part_a_deductible_1600}</span>
                              )}
                              <FeatureName>
                                Part A Deductible
                              </FeatureName>
                            </FeaturesListItem>
                          ) : (
                            <FeaturesListItem>
                              <BsXLg
                                fontSize='14px'
                                color={theme.colors.secondary}
                              />
                              <FeatureName>
                                Part A Deductible
                              </FeatureName>
                            </FeaturesListItem>
                          )}
                          {medigapChartDetails[msPlan.planName] && medigapChartDetails[msPlan.planName].part_b_deductible_226 !== '0%' ? (
                            <FeaturesListItem>
                              {medigapChartDetails[msPlan.planName].part_b_deductible_226 === '100%' ? (
                                <BsCheckLg
                                  fontSize='14px'
                                  color={theme.colors.green}
                                />
                              ) : (
                                <span style={{ color: 'rgb(69 147 8)' }}>{medigapChartDetails[msPlan.planName].part_b_deductible_226}</span>
                              )}
                              <FeatureName>Part B Deductible</FeatureName>
                            </FeaturesListItem>
                          ) : (
                            <FeaturesListItem>
                              <BsXLg
                                fontSize='14px'
                                color={theme.colors.secondary}
                              />
                              <FeatureName>Part B Deductible</FeatureName>
                            </FeaturesListItem>
                          )}
                          {medigapChartDetails[msPlan.planName] && medigapChartDetails[msPlan.planName].part_b_excess_charges !== '0%' ? (
                            <FeaturesListItem>
                              <BsCheckLg
                                fontSize='14px'
                                color={theme.colors.green}
                              />
                              <FeatureName>Part B Excess Charges</FeatureName>
                            </FeaturesListItem>
                          ) : (
                            <FeaturesListItem>
                              <BsXLg
                                fontSize='14px'
                                color={theme.colors.secondary}
                              />
                              <FeatureName>Part B Excess Charges</FeatureName>
                            </FeaturesListItem>
                          )}
                        </FeaturesList>
                      </FeaturesRow>
                    )}

                  {/* <h2>Do you know?</h2>
                <p>
                  You can avail upto 15% household discount on buying a plan for
                  both you and your spouse!
                </p>
                <div>
                  <RadioGroup
                    radioButtons={[
                      'Show only my rates',
                      'Show discounted rates for both me and my spouse (save upto 15%)',
                    ]}
                    onChange={(e) => {
                      e.target.value === 'Show only my rates'
                        ? setSpouse(false)
                        : setSpouse(true)
                    }}
                    type='unstyled'
                    fontSize='14px'
                  />
                </div> */}
                </BenefitContent>
              </BenefitsWrapper>
              <PricingWrapper>
                <h2>{spouse === true ? 'Combined' : ''} Monthly Premium</h2>
                <p>
                  {spouse === true
                    ? convertToUSCurrency(msPlan.combinedMonthlyPremium)
                    : convertToUSCurrency(monthlyPremium)}
                </p>
                {msPlan.discounts &&
                  msPlan.discounts.find((x) => x.name === 'household') && (
                    <>
                      <p
                        style={{
                          display: 'flex',
                          marginTop: '4px',
                          alignItems: 'center',
                          fontWeight: '600',
                          fontSize: '12px',
                          color: theme.colors.textDark,
                        }}
                      >
                        <span className='discounts'>
                          With Household Discount*
                          <Tooltip
                            title={StringConstants.TOOLTIP_MSG_MS_HH_DISCOUNT}
                          ></Tooltip>
                          <> </>
                        </span>
                        <span className='discounts'>:</span>
                        <span
                          className='discounts'
                          style={{
                            color: theme.colors.primary,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: '0.25rem',
                            fontWeight: 'bold',
                          }}
                        >
                          $
                          {msPlan.withHHD && msPlan.withHHD !== 0
                            ? msPlan.withHHD && (msPlan.withHHD / 100).toFixed(2)
                            : (
                              monthlyPremium *
                              (1 -
                                (msPlan.discounts.find(
                                  (x) => x.name === 'household'
                                )?.value || 0))
                            ).toFixed(2)}
                        </span>
                      </p>
                    </>
                  )}
                {msPlan.policyFees !== 0 && msPlan.policyFees && (
                  <>
                    <p
                      style={{
                        display: 'flex',
                        marginTop: '4px',
                        alignItems: 'center',
                        fontWeight: '600',
                        fontSize: '12px',
                        color: theme.colors.textDark,
                      }}
                    >
                      <span className='policyFee'>One-time policy fee</span>
                      <span className='policyFee'>:</span>
                      <span
                        className='policyFee'
                        style={{
                          color: theme.colors.primary,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginLeft: '0.25rem',
                          fontWeight: 'bold',
                        }}
                      >
                        ${msPlan.policyFees.toFixed(2)}
                      </span>
                    </p>
                  </>
                )}
                <Button
                  variant='contained'
                  color='secondary'
                  width='100%'
                  onClick={() => {
                    if (!isEmpty(msPlan.enrollmentRedirectURL))
                      window.open(msPlan.enrollmentRedirectURL, '_blank');
                    handleEnrollRequest()
                  }}
                  disabled={enrollRequested}
                  additionalPadding={true}
                  loading={loading}
                  description={`Attempted Enrollment in ${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})`}
                  id={`enroll ${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})`}
                >
                  {enrollBtnText}
                </Button>
                {showRiders && (
                  <Button
                    variant='text'
                    color='primary'
                    fontSize='12px'
                    borderRadius='4px'
                    textDecoration='underline'
                    description={'Select the riders applied'}
                    onClick={(e) => {
                      handleOpenRiders()
                      // fireEvent(
                      //   isExpanded ? 'viewLessCardDetails' : 'viewMoreCardDetails',
                      //   e,
                      //   {
                      //     value: !isExpanded ? 'expanding' : 'collapsing',
                      //     planId: msPlan.id,
                      //     planType: MedicareQuotesType.MEDICARE_SUPPLEMENT,
                      //     description: isExpanded
                      //       ? 'View Less Card Details'
                      //       : 'View More Card Details',
                      //   }
                      // )
                    }}
                  >
                    <>Click here to view the riders applied</>
                  </Button>
                )}
                {/*
              {currentPlanExist && (
                <div>
                  <p>
                    {spouse === true
                      ? convertToUSCurrency(
                          Math.abs(
                            msPlan.combinedEstimatedAnnualCost -
                              combinedCurrentPlanAnnualCost
                          )
                        )
                      : convertToUSCurrency(
                          Math.abs(
                            msPlan.estimatedAnnualCost -
                              currentPlanAnnualCost
                          )
                        )}
                  </p>
                  <span>
                    {spouse === true ? 'Combined' : 'Estimated'} Annual Savings{' '}
                    <br /> (prescription drugs not covered)
                  </span>
                </div>
              )} */}
                {/* {!isUndefined(msPlan.estimatedAnnualCost) && (
                <div>
                  <span>
                    {spouse === true ? 'Combined' : 'Estimated'} Annual Cost{' '}
                  </span>
                  <p>
                    {spouse === true
                      ? convertToUSCurrency(msPlan.combinedEstimatedAnnualCost)
                      : convertToUSCurrency(msPlan.estimatedAnnualCost)}
                  </p>
                </div>
              )} */}
                {/* <div>
                <p className='info'>
                  You need to buy a separate Part D plan to get your
                  prescription drugs covered.
                </p>
              </div> */}
              </PricingWrapper>
            </ContentWrapper>
            <FooterWrapper>
              <SavePlanIconButton
                planId={msPlan.id}
                planType={MedicareQuotesType.MEDICARE_SUPPLEMENT}
                saved={isFavorite}
                onAddSaved={onAddFavPlan}
                onRemoveSaved={onRemoveFavPlan}
              />
              {msPlan.msPlanGenericDetails &&
                msPlan.msPlanGenericDetails.length > 0 && (
                  <div>
                    <Button
                      variant='text'
                      color='primary'
                      fontSize='14px'
                      borderRadius='4px'
                      bold={false}
                      endIcon={
                        isExpanded ||
                          `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})` ===
                          customerStore.searchPlan ? (
                          <ArrowUp style={{ fontSize: '12px' }} />
                        ) : (
                          <ArrowDown style={{ fontSize: '12px' }} />
                        )
                      }
                      description={
                        isExpanded ||
                          `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})` ===
                          customerStore.searchPlan
                          ? 'View Less Details'
                          : 'View More Details'
                      }
                      onClick={(e) => {
                        handleExpandCard(msPlan)
                        fireEvent(
                          isExpanded ||
                            `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})` ===
                            customerStore.searchPlan
                            ? 'viewLessCardDetails'
                            : 'viewMoreCardDetails',
                          e,
                          {
                            value:
                              !isExpanded ||
                                `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})` !==
                                customerStore.searchPlan
                                ? 'expanding'
                                : 'collapsing',
                            planId: msPlan.id,
                            planType: MedicareQuotesType.MEDICARE_SUPPLEMENT,
                            description:
                              isExpanded ||
                                `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})` ===
                                customerStore.searchPlan
                                ? 'View Less Card Details'
                                : 'View More Card Details',
                          }
                        )
                      }}
                    >
                      <>
                        View{' '}
                        {isExpanded ||
                          `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})` ===
                          customerStore.searchPlan
                          ? 'less'
                          : 'more'}{' '}
                        details
                      </>
                    </Button>
                  </div>
                )}
            </FooterWrapper>
            <MoreDetailsWrapper
              isExpanded={
                isExpanded ||
                `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})` ===
                customerStore.searchPlan
              }
            >
              {customerStore?.msExpandedCardDetails?.planDetail?.map(
                (details, index) => (
                  <div key={index}>
                    <p>
                      {details.partType}{' '}
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          navigate({
                            pathname: '/plan-details',
                            search: `id=${msPlan.id}&&type=ms`,
                          })
                          storeScrollPosition()
                        }}
                      >
                        View complete plan details
                      </span>
                    </p>
                    <CardTable rows={details.partTypeDetails} />
                  </div>
                )
              )}
              <FooterWrapper>
                <SavePlanIconButton
                  planId={msPlan.id}
                  planType={MedicareQuotesType.MEDICARE_SUPPLEMENT}
                  saved={isFavorite}
                  onAddSaved={onAddFavPlan}
                  onRemoveSaved={onRemoveFavPlan}
                />
                <div>
                  <Button
                    variant='text'
                    color='primary'
                    fontSize='14px'
                    bold={false}
                    endIcon={
                      isExpanded ||
                        `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})` ===
                        customerStore.searchPlan ? (
                        <ArrowUp style={{ fontSize: '12px' }} />
                      ) : (
                        <ArrowDown style={{ fontSize: '12px' }} />
                      )
                    }
                    description={
                      isExpanded ||
                        `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})` ===
                        customerStore.searchPlan
                        ? 'View Less Details'
                        : 'View More Details'
                    }
                    onClick={(e) => {
                      handleExpandCard(msPlan)
                      fireEvent(
                        isExpanded ||
                          `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})` ===
                          customerStore.searchPlan
                          ? 'viewLessCardDetails'
                          : 'viewMoreCardDetails',
                        e,
                        {
                          value: !isExpanded ? 'expanding' : 'collapsing',
                          planId: msPlan.id,
                          planType: MedicareQuotesType.MEDICARE_SUPPLEMENT,
                          description:
                            isExpanded ||
                              `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})` ===
                              customerStore.searchPlan
                              ? 'View Less Card Details'
                              : 'View More Card Details',
                        }
                      )
                    }}
                  >
                    <>
                      View{' '}
                      {isExpanded ||
                        `${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})` ===
                        customerStore.searchPlan
                        ? 'less'
                        : 'more'}{' '}
                      details
                    </>
                  </Button>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                      if (!isEmpty(msPlan.enrollmentRedirectURL))
                        window.open(msPlan.enrollmentRedirectURL, '_blank');
                      handleEnrollRequest()
                    }}
                    loading={loading}
                    disabled={enrollRequested}
                    additionalPadding={true}
                    description={`Attempted Enrollment in ${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})`}
                    id={`enroll ${msPlan.msCompanyBase.name} (Plan ${msPlan.planName})`}
                  >
                    {enrollBtnText}
                  </Button>
                </div>
              </FooterWrapper>
            </MoreDetailsWrapper>
          </Wrapper>
        )}
      </Container>
    )
  }

export default observer(MedicareSupplementCard)
