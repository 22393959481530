const UrlConstants: { [key: string]: { USECASE: string; URL: string } } = {
  CDN_ENDPOINT: {
    USECASE: 'CDN_ENDPOINT',
    URL: `${process.env.REACT_APP_LAMBDA_URL}`,
  },

  SIGNIN: {
    USECASE: 'SIGNIN',
    URL: '/api/v1/auth/login',
  },
  LOGOUT: {
    USECASE: 'LOGOUT',
    URL: '/api/v1/auth/logout',
  },
  CHANGE_PASSWORD: {
    USECASE: 'CHANGE_PASSWORD',
    URL: '/api/v1/customer/change-password',
  },
  GET_CUSTOMER_DETAILS: {
    USECASE: 'GET_CUSTOMER_DETAILS',
    URL: '/api/v1/customer/profile',
  },
  UPDATE_CUSTOMER_DETAILS: {
    USECASE: 'UPDATE_CUSTOMER_DETAILS',
    URL: '/api/v1/form',
  },

  SUBMIT_PRIMARY_FORM: {
    USECASE: 'SUBMIT_PRIMARY_FORM',
    URL: '/api/v1/form/primary',
  },

  FORGOT_PASSWORD: {
    USECASE: 'FORGOT_PASSWORD',
    URL: '/api/v1/auth/forgot-password',
  },
  VALIDATE_FORGOT_PASSWORD: {
    USECASE: 'VALIDATE_FORGOT_PASSWORD',
    URL: '/api/v1/auth/$token/validate',
  },
  RESET_FORGOT_PASSWORD: {
    USECASE: 'RESET_FORGOT_PASSWORD',
    URL: '/api/v1/auth/$token/change-password',
  },
  RETRIEVE_COMPARE_PLAN_DETAILS: {
    USECASE: 'RETRIEVE_COMPARE_PLAN_DETAILS',
    URL: '/api/v1/quotes/compare-plans',
  },
  RETRIEVE_MNS_COMPARE_PLAN_DETAILS: {
    USECASE: 'RETRIEVE_MNS_COMPARE_PLAN_DETAILS',
    URL: '/api/v1/quotes/sf/compare',
  },
  ADD_SPOUSE: {
    USECASE: 'ADD_SPOUSE',
    URL: '/api/v1/customer/spouse',
  },
  GET_PHARMACY_WITH_ZIP: {
    USECASE: 'GET_PHARMACY_WITH_ZIP',
    URL: '/api/v1/pharmacy/csg',
  },
  POST_PHARMACY_DETAILS: {
    USECASE: 'POST_PHARMACY_DETAILS',
    URL: '/api/v1/pharmacy',
  },
  POST_PHARMACIES_DETAILS: {
    USECASE: 'POST_PHARMACIES_DETAILS',
    URL: '/api/v1/pharmacy/pharmacies',
  },
  GET_SELECTED_PHARMACY_DETAILS: {
    USECASE: 'GET_SELECTED_PHARMACY_DETAILS',
    URL: '/api/v1/pharmacy',
  },
  DELETE_PHARMACY_DETAIL: {
    USECASE: 'DELETE_PHARMACY_DETAIL',
    URL: '/api/v1/pharmacy/$pharmacyId',
  },
  GET_RX_DRUG_NAME: {
    USECASE: 'GET_RX_DRUG_NAME',
    URL: '/api/v1/prescriptions/lookup/$drugPrefix/-1',
  },
  GET_RX_DOSAGE_FOR_DRUG_NAME: {
    USECASE: 'GET_RX_DOSAGE_FOR_DRUG_NAME',
    URL: '/api/v1/prescriptions/dosage/$drugName',
  },
  GET_PRESCRIPTION_DRUG: {
    USECASE: 'GET_PRESCRIPTION_DRUG',
    URL: '/api/v1/prescriptions',
  },
  ADD_PRESCRIPTION_DRUG: {
    USECASE: 'ADD_PRESCRIPTION_DRUG',
    URL: '/api/v1/prescriptions',
  },
  DELETE_PRESCRIPTION_DRUG: {
    USECASE: 'DELETE_PRESCRIPTION_DRUG',
    URL: '/api/v1/prescriptions/$prescriptionDrugId',
  },
  UPDATE_PRESCRIPTION_DRUG: {
    USECASE: 'UPDATE_PRESCRIPTION_DRUG',
    URL: '/api/v1/prescriptions',
  },
  REFRESH_DRUG_COST: {
    USECASE: 'REFRESH_DRUG_COST',
    URL: '/api/v1/prescriptions/refresh-drug-cost',
  },
  GET_PROFILE_PREFERENCES: {
    USECASE: 'GET_PROFILE_PREFERENCES',
    URL: '/api/v1/customer/preference',
  },
  PATCH_PROFILE_PREFERENCES: {
    USECASE: 'PATCH_PROFILE_PREFERENCES',
    URL: '/api/v1/customer/preference',
  },
  POST_PROFILE_PREFERENCES: {
    USECASE: 'POST_PROFILE_PREFERENCES',
    URL: '/api/v1/customer/preference',
  },
  GET_SUPPLEMENT_FILTERS: {
    USECASE: 'GET_SUPPLEMENT_FILTERS',
    URL: '/api/v1/quotes/filters',
  },
  GET_MA_PLAN_FILTERS: {
    USECASE: 'GET_MA_PLAN_FILTERS',
    URL: '/api/v1/quotes/ma-plan/filters',
  },
  GET_PRESCRIPTION_D_FILTERS: {
    USECASE: 'GET_PRESCRIPTION_D_FILTERS',
    URL: '/api/v1/quotes/sf/pdp/filters',
  },
  GET_MA_PLANS: {
    USECASE: 'GET_MA_PLANS',
    URL: '/api/v1/quotes/ma-plan',
  },
  GET_PRESCRIPTION_D_PLANS: {
    USECASE: 'GET_PRESCRIPTION_D_PLANS',
    URL: '/api/v1/quotes/sf/pdp',
  },
  GET_SUPPLEMENT_PLANS: {
    USECASE: 'GET_SUPPLEMENT_PLANS',
    URL: '/api/v1/quotes',
  },
  POST_CURRENT_PLAN: {
    USECASE: 'POST_CURRENT_PLAN',
    URL: '/api/v1/customer/current-plan',
  },
  PATCH_SUPPLEMENTAL_QUESTIONS: {
    USECASE: 'PATCH_SUPPLEMENTAL_QUESTIONS',
    URL: '/api/v1/customer/supplemental-questions',
  },
  GET_HEALTH_QUESTIONNAIRE: {
    USECASE: 'GET_HEALTH_QUESTIONNAIRE',
    URL: '/api/v1/customer/survey',
  },
  POST_HEALTH_QUESTIONNAIRE_RESPONSE: {
    USECASE: 'POST_HEALTH_QUESTIONNAIRE_RESPONSE',
    URL: '/api/v1/customer/survey/$forSpouse',
  },
  GET_MEDICARE_ADVANTAGE_PLANS: {
    USECASE: 'GET_MEDICARE_ADVANTAGE_PLANS',
    URL: '/api/v1/quotes/sf/ma',
  },
  GET_MEDICARE_ADVANTAGE_PLAN: {
    USECASE: 'GET_MEDICARE_ADVANTAGE_PLAN',
    URL: '/api/v1/quotes/ma-plan/$planId',
  },
  GET_MEDICARE_ADVANTAGE_PLANS_FILTERS: {
    USECASE: 'GET_MEDICARE_ADVANTAGE_PLANS_FILTERS',
    URL: '/api/v1/quotes/sf/ma/filters',
  },
  GET_MEDICARE_SUPPLEMENT_PLANS: {
    USECASE: 'GET_MEDICARE_SUPPLEMENT_PLANS',
    URL: '/api/v1/quotes',
  },
  GET_MEDICARE_SUPPLEMENT_PLANS_FILTERS: {
    USECASE: 'GET_MEDICARE_SUPPLEMENT_PLANS_FILTERS',
    URL: '/api/v1/quotes/filters',
  },
  GET_MEDICARE_SUPPLEMENT_PLAN_DETAILS: {
    USECASE: 'GET_MEDICARE_SUPPLEMENT_PLAN_DETAILS',
    URL: '/api/v1/quotes/plan-detail/$planType',
  },
  GET_MEDICARE_SUPPLEMENT_COMPLETE_PLAN_DETAILS: {
    USECASE: 'GET_MEDICARE_SUPPLEMENT_COMPLETE_PLAN_DETAILS',
    URL: '/api/v1/quotes/ms-plan/$planId',
  },
  GET_MEDICARE_PART_D_PLANS_FILTERS: {
    USECASE: 'GET_MEDICARE_PART_D_PLANS_FILTERS',
    URL: '/api/v1/quotes/sf/pdp/filters',
  },
  GET_MEDICARE_PART_D_PLANS: {
    USECASE: 'GET_MEDICARE_PART_D_PLANS',
    URL: '/api/v1/quotes/sf/pdp',
  },
  GET_MEDICARE_PART_D_PLAN: {
    USECASE: 'GET_MEDICARE_PART_D_PLAN',
    URL: '/api/v1/quotes/m-part-d-plan/$planId',
  },
  GET_SAVED_PLANS: {
    USECASE: 'GET_SAVED_PLANS',
    URL: '/api/v1/quotes/saved-plans',
  },
  SAVE_PLAN: {
    USECASE: 'SAVE_PLAN',
    URL: '/api/v1/quotes/save',
  },
  DELETE_SAVED_PLAN: {
    USECASE: 'DELETE_SAVED_PLAN',
    URL: '/api/v1/quotes/saved-plans/$planId',
  },
  GET_INFO_STEPS: {
    USECASE: 'GET_INFO_STEPS',
    URL: '/api/v1/customer/info-step',
  },
  PATCH_INFO_STEPS: {
    USECASE: 'PATCH_INFO_STEPS',
    URL: '/api/v1/customer/info-step/$infoStep',
  },
  DELETE_INFO_STEPS: {
    USECASE: 'DELETE_INFO_STEPS',
    URL: '/api/v1/customer/info-step/$infoStep',
  },
  PATCH_ENROLL_REQUESTED: {
    USECASE: 'PATCH_ENROLL_REQUESTED',
    URL: '/api/v1/quotes/enroll/$planId',
  },
  PATCH_PREFERRED_NUMBER_ENROLL_REQUESTED: {
    USECASE: 'PATCH_PREFERRED_NUMBER_ENROLL_REQUESTED',
    URL: '/api/v1/quotes/enroll/preferred-number',
  },
  GET_MEDICARE_PLANS_COUNT: {
    USECASE: 'GET_MEDICARE_PLANS_COUNT',
    URL: '/api/v1/quotes/sf/count',
  },
  POST_EMAIL_PLAN_DETAILS: {
    USECASE: 'POST_EMAIL_PLAN_DETAILS',
    URL: '/api/v1/quotes/email-plan-details',
  },

  POST_EMAIL_MNS_PLAN_DETAILS: {
    USECASE: 'POST_EMAIL_MNS_PLAN_DETAILS',
    URL: '/api/v1/quotes/sf/email-plan-details',
  },

  GET_QUOTES_PULLED_STATUS: {
    USECASE: 'GET_QUOTES_PULLED_STATUS',
    URL: '/api/v1/customer/quotes/status',
  },
  GET_SUPPLEMENT_PLANS_FOR_POP_UP: {
    USECASE: 'GET_SUPPLEMENT_PLANS_FOR_POP_UP',
    URL: '/api/v1/quotes/public/ms-quotes',
  },
  GET_ADVANTAGE_PLANS_FOR_POP_UP: {
    USECASE: 'GET_ADVANTAGE_PLANS_FOR_POP_UP',
    URL: '/api/v1/quotes/public/ma-quotes',
  },
  GET_PART_D_PLANS_FOR_POP_UP: {
    USECASE: 'GET_PART_D_PLANS_FOR_POP_UP',
    URL: '/api/v1/quotes/public/mpartD-quotes',
  },
  PATCH_RIDERS_APPLIED_FOR_MS_PLAN: {
    USECASE: 'PATCH_RIDERS_APPLIED_FOR_MS_PLAN',
    URL: '/api/v1/quotes/ms-plan/$planId/riders',
  },
  VERIFY_PHONE_NUMBER: {
    USECASE: 'VERIFY_PHONE_NUMBER',
    URL: '/api/v1/form/phone-verification/$phone',
  },
  SF_MO1_UPDATE: {
    USECASE: 'SF_MO1_UPDATE',
    URL: '/api/v1/form/medicare101',
  },
  SF_CALLBACK_LEAD_INFO_UPDATE: {
    USECASE: 'SF_CALLBACK_LEAD_INFO_UPDATE',
    URL: '/api/v1/form/lead-callback-fields',
  },
  GET_ENROLLMENT_COUNT: {
    USECASE: 'GET_ENROLLMENT_COUNT',
    URL: '/api/v1/quotes/enrollment-count',
  },
  GET_MEDIGAP_CHART_DETAILS: {
    USECASE: 'GET_MEDIGAP_CHART_DETAILS',
    URL: '/api/v1/quotes/medigap-chart-details',
  },

  //Sunfire

  //Location
  GET_CITY_DETAILS: {
    USECASE: 'GET_CITY_DETAILS',
    URL: '/api/v1/location/zip/city/details/$zipcode/$radius/$size',
  },

  //Providers
  GET_PROVIDERS_CITIES: {
    USECASE: 'GET_PROVIDERS_CITIES',
    URL: '',
  },
  GET_PROVIDER_ADDRESS: {
    USECASE: 'GET_PROVIDER_ADDRESS',
    URL: '/api/v1/provider/address',
  },
  LOOKUP_PROVIDERS: {
    USECASE: 'LOOKUP_PROVIDERS',
    URL: '/api/v1/provider/lookup/$name',
  },
  GET_PROVIDERS: {
    USECASE: 'GET_PROVIDERS',
    URL: '/api/v1/provider/$forSpouse',
  },
  ADD_PROVIDER: {
    USECASE: 'ADD_PROVIDER',
    URL: '/api/v1/provider/$npi/external/$id/customer/$forSpouse',
  },
  UPDATE_PROVIDER_ADDRESS: {
    USECASE: 'UPDATE_PROVIDER_ADDRESS',
    URL: '/api/v1/provider/$id/$npi/$externalId/customer/$forSpouse',
  },
  DELETE_PROVIDER: {
    USECASE: 'DELETE_PROVIDER',
    URL: '/api/v1/provider/$id/$forSpouse',
  },

  //Plans
  GET_COMPLETE_PLAN_DETAILS_SF: {
    USECASE: 'GET_COMPLETE_PLAN_DETAILS_SF',
    URL: '/api/v1/quotes/sf/details/$planId',
  },
  //Pharmacy
  GET_PHARMACY_WITH_ZIP_SF: {
    USECASE: 'GET_PHARMACY_WITH_ZIP_SF',
    URL: '/api/v1/pharmacy/sf',
  },
  POST_PHARMACY_DETAILS_INFO_STEPS_SF: {
    USECASE: 'POST_PHARMACY_DETAILS_INFO_STEPS_SF',
    URL: '/api/v1/pharmacy/pharmacies/sf/$forSpouse',
  },
  POST_PHARMACY_DETAILS_SF: {
    USECASE: 'POST_PHARMACY_DETAILS_SF',
    URL: '/api/v1/pharmacy/sf/$pharmacyId/$forSpouse',
  },
  DELETE_PHARMACY_DETAIL_SF: {
    USECASE: 'DELETE_PHARMACY_DETAIL_SF',
    URL: '/api/v1/pharmacy/sf/$pharmacyId/$forSpouse',
  },
  GET_SELECTED_PHARMACY_DETAILS_SF: {
    USECASE: 'GET_SELECTED_PHARMACY_DETAILS_SF',
    URL: '/api/v1/pharmacy/sf/$forSpouse',
  },
  //Prescriptions
  GET_RX_DRUG_NAME_SF: {
    USECASE: 'GET_RX_DRUG_NAME_SF',
    URL: '/api/v1/prescriptions/sf/lookup/$drugPrefix/-1',
  },
  GET_RX_DOSAGE_FOR_DRUG_NAME_SF: {
    USECASE: 'GET_RX_DOSAGE_FOR_DRUG_NAME_SF',
    URL: '/api/v1/prescriptions/sf/dosage/$drugName',
  },
  GET_PRESCRIPTION_DRUG_SF: {
    USECASE: 'GET_PRESCRIPTION_DRUG_SF',
    URL: '/api/v1/prescriptions/sf',
  },
  ADD_PRESCRIPTION_DRUG_SF: {
    USECASE: 'ADD_PRESCRIPTION_DRUG_SF',
    URL: '/api/v1/prescriptions/sf',
  },
  DELETE_PRESCRIPTION_DRUG_SF: {
    USECASE: 'DELETE_PRESCRIPTION_DRUG_SF',
    URL: '/api/v1/prescriptions/sf/$prescriptionDrugId',
  },
  UPDATE_PRESCRIPTION_DRUG_SF: {
    USECASE: 'UPDATE_PRESCRIPTION_DRUG_SF',
    URL: '/api/v1/prescriptions/sf',
  },
  BULK_INSERT_PRESCRIPTION_DRUG: {
    USECASE: 'BULK_INSERT_PRESCRIPTION_DRUG',
    URL: '/api/v1/prescriptions/sf/bulk/$forSpouse',
  },
  // Milliman
  GET_MILLIMAN_PRESCRIPTION_RESPONSE_STATUS: {
    USECASE: 'GET_MILLIMAN_PRESCRIPTION_RESPONSE_STATUS',
    URL: '/api/v1/prescriptions/sf/prefilled/status',
  },
  GET_MILLIMAN_PRESCRIPTION_DRUG: {
    USECASE: 'GET_MILLIMAN_PRESCRIPTION_DRUG',
    URL: '/api/v1/prescriptions/sf/prefilled',
  },
  MAKE_MILLIMAN_PRESCRIPTION_ORDER_REQUEST: {
    USECASE: 'MAKE_MILLIMAN_PRESCRIPTION_ORDER_REQUEST',
    URL: '/api/v1/form',
  },
}

export default UrlConstants
